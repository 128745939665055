<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > <router-link :to="{path:'/reservation'}">预约服务</router-link> > {{getIndex(type)}}</div>
			<img class="titleImg" src="@/assets/images/xuzhi_title.png"/>
			<div class="content">
				<p>预约须知</p>
				<div class="content_text" v-html="detail"></div>
				<div class="btn">
					<el-button type="primary" @click="toStepOne">已阅读并同意</el-button>
				</div>


			</div>
			
			
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				type: '',
				detail: null,
				
			}
		},
		created() {
			
		},
		mounted() {
			this.type = this.$route.query.type
			this.getDetail()
		},
		methods: {
			getDetail() {
				let formData = new FormData()
					formData.append('type', this.type)
				this.$http({
					url: `/bookingRecordApi/queryBookingInstructions`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.detail = res.data
					}
					
				});
			},
			getIndex(index) {
				if(index == 0) return '个人预约'
				if(index == 1) return '团队预约'
				if(index == 2) return '政务接待'
			},
			toStepOne() {
				this.$router.push({ path: "/Reservation/step_one",query:{type:this.type}});
			}
			
			
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		min-height: calc(100vh - 354px);
		background: #FFFFFF;
		padding-bottom: 60px;
		.titleImg{
			margin: 0 auto;
			margin-top: 30px;
		}
		.content{
			margin: 0 auto;
			margin-top: 20px;
			background: url('../../assets/images/kuang.png') no-repeat;
			background-size: 100% 100%;
			min-height: 453px;
			width: 950px;
			padding: 30px 60px;
			>p{
				text-align: center;
				font-size: 18px;
				margin-bottom: 20px;
			}
			.content_text{
				min-height: 250px;
				line-height: 25px;
			}
			.btn{
				text-align: center;
				margin-top: 20px;
			}
		}
	
	}
}
	
</style>
